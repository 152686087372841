<script>
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import BaseHeader from '/~/components/base/header/base-header.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { formatNumber } from '/~/utils/format/numeric'

export default {
  name: 'rec-earn-status-transactions',
  components: {
    RecognitionHeader,
    EwCatalogProcessor,
    BaseHeader,
    BaseMdl,
  },
  props: {
    transactions: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      default: 'points',
    },
    totalEarn: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      formatNumber,
    }
  },
}
</script>

<template>
  <base-mdl width="sme" :layout="false">
    <base-header class="text-white" type="closable">
      <template #subheader>
        <recognition-header color="warning" icon="rec/round/token" animated />
      </template>
    </base-header>
    <h2 class="mb-5 text-center text-2xl">
      {{ formatNumber(totalEarn) }}
      <span class="capitalize">
        {{ currency }}
      </span>
      given in this
      <span class="capitalize">
        {{ type }}
      </span>
    </h2>

    <div
      class="flex h-12 border-b border-t bg-dark px-6 font-bold text-primary"
    >
      <div class="py-2.5 pr-2.5" style="width: 25%">Member</div>
      <div class="px-2.5 py-2.5" style="width: 30%">Action</div>
      <div class="px-2.5 py-2.5" style="width: 30%">Time</div>
      <div class="py-2.5 pl-2.5 text-center capitalize" style="width: 15%">
        {{ currency }}
      </div>
    </div>
    <div class="p-6 text-sm">
      <ew-catalog-processor
        :processor="transactions"
        :columns="1"
        :tile-class="false"
      >
        <template #empty>
          <div>
            No Transaction Logs found for this
            <span class="capitalize">
              {{ type }}
            </span>
          </div>
        </template>
        <template #tile="{ tile: log }">
          <div class="-my-[5px] flex w-full">
            <div class="truncate pr-2.5 font-bold" style="width: 25%">
              {{ log.memberFullName }}
            </div>
            <div class="truncate px-2.5" style="width: 30%">
              {{ log.actionTitle }}
            </div>
            <div class="px-2.5" style="width: 30%">
              {{ log.createdAt }}
            </div>
            <div class="pl-2.5 text-center font-bold" style="width: 15%">
              {{ log.transactionValue }}
            </div>
          </div>
        </template>
      </ew-catalog-processor>
    </div>
  </base-mdl>
</template>
